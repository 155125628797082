.__flag_af {
  content: url('src/static/flags/af.svg');
  width: 24px;
  height: 24px;
}
.__flag_al {
  content: url('src/static/flags/al.svg');
  width: 24px;
  height: 24px;
}
.__flag_dz {
  content: url('src/static/flags/dz.svg');
  width: 24px;
  height: 24px;
}
.__flag_as {
  content: url('src/static/flags/as.svg');
  width: 24px;
  height: 24px;
}
.__flag_ad {
  content: url('src/static/flags/ad.svg');
  width: 24px;
  height: 24px;
}
.__flag_ao {
  content: url('src/static/flags/ao.svg');
  width: 24px;
  height: 24px;
}
.__flag_ai {
  content: url('src/static/flags/ai.svg');
  width: 24px;
  height: 24px;
}
.__flag_ai {
  content: url('src/static/flags/ai.svg');
  width: 24px;
  height: 24px;
}

.__flag_ag {
  content: url('src/static/flags/ag.svg');
  width: 24px;
  height: 24px;
}
.__flag_ar {
  content: url('src/static/flags/ar.svg');
  width: 24px;
  height: 24px;
}
.__flag_am {
  content: url('src/static/flags/am.svg');
  width: 24px;
  height: 24px;
}
.__flag_aw {
  content: url('src/static/flags/aw.svg');
  width: 24px;
  height: 24px;
}
.__flag_au {
  content: url('src/static/flags/au.svg');
  width: 24px;
  height: 24px;
}
.__flag_at {
  content: url('src/static/flags/at.svg');
  width: 24px;
  height: 24px;
}
.__flag_az {
  content: url('src/static/flags/az.svg');
  width: 24px;
  height: 24px;
}
.__flag_bs {
  content: url('src/static/flags/bs.svg');
  width: 24px;
  height: 24px;
}
.__flag_bh {
  content: url('src/static/flags/bh.svg');
  width: 24px;
  height: 24px;
}
.__flag_bd {
  content: url('src/static/flags/bd.svg');
  width: 24px;
  height: 24px;
}
.__flag_bb {
  content: url('src/static/flags/bb.svg');
  width: 24px;
  height: 24px;
}
.__flag_by {
  content: url('src/static/flags/by.svg');
  width: 24px;
  height: 24px;
}
.__flag_be {
  content: url('src/static/flags/be.svg');
  width: 24px;
  height: 24px;
}
.__flag_bz {
  content: url('src/static/flags/bz.svg');
  width: 24px;
  height: 24px;
}
.__flag_bj {
  content: url('src/static/flags/bj.svg');
  width: 24px;
  height: 24px;
}
.__flag_bm {
  content: url('src/static/flags/bm.svg');
  width: 24px;
  height: 24px;
}
.__flag_bt {
  content: url('src/static/flags/bt.svg');
  width: 24px;
  height: 24px;
}
.__flag_bo {
  content: url('src/static/flags/bo.svg');
  width: 24px;
  height: 24px;
}
.__flag_ba {
  content: url('src/static/flags/ba.svg');
  width: 24px;
  height: 24px;
}
.__flag_bw {
  content: url('src/static/flags/bw.svg');
  width: 24px;
  height: 24px;
}
.__flag_br {
  content: url('src/static/flags/br.svg');
  width: 24px;
  height: 24px;
}
.__flag_io {
  content: url('src/static/flags/io.svg');
  width: 24px;
  height: 24px;
}
.__flag_vg {
  content: url('src/static/flags/vg.svg');
  width: 24px;
  height: 24px;
}
.__flag_bn {
  content: url('src/static/flags/bn.svg');
  width: 24px;
  height: 24px;
}
.__flag_bg {
  content: url('src/static/flags/bg.svg');
  width: 24px;
  height: 24px;
}
.__flag_bf {
  content: url('src/static/flags/bf.svg');
  width: 24px;
  height: 24px;
}
.__flag_mm {
  content: url('src/static/flags/mm.svg');
  width: 24px;
  height: 24px;
}
.__flag_bi {
  content: url('src/static/flags/bi.svg');
  width: 24px;
  height: 24px;
}
.__flag_kh {
  content: url('src/static/flags/kh.svg');
  width: 24px;
  height: 24px;
}
.__flag_cm {
  content: url('src/static/flags/cm.svg');
  width: 24px;
  height: 24px;
}
.__flag_ca {
  content: url('src/static/flags/ca.svg');
  width: 24px;
  height: 24px;
}
.__flag_ky {
  content: url('src/static/flags/ky.svg');
  width: 24px;
  height: 24px;
}
.__flag_cf {
  content: url('src/static/flags/cf.svg');
  width: 24px;
  height: 24px;
}
.__flag_td {
  content: url('src/static/flags/td.svg');
  width: 24px;
  height: 24px;
}
.__flag_cl {
  content: url('src/static/flags/cl.svg');
  width: 24px;
  height: 24px;
}
.__flag_cn {
  content: url('src/static/flags/cn.svg');
  width: 24px;
  height: 24px;
}
.__flag_cx {
  content: url('src/static/flags/cx.svg');
  width: 24px;
  height: 24px;
}
.__flag_cc {
  content: url('src/static/flags/cc.svg');
  width: 24px;
  height: 24px;
}
.__flag_co {
  content: url('src/static/flags/co.svg');
  width: 24px;
  height: 24px;
}
.__flag_km {
  content: url('src/static/flags/km.svg');
  width: 24px;
  height: 24px;
}
.__flag_cg {
  content: url('src/static/flags/cg.svg');
  width: 24px;
  height: 24px;
}
.__flag_cd {
  content: url('src/static/flags/cd.svg');
  width: 24px;
  height: 24px;
}
.__flag_ck {
  content: url('src/static/flags/ck.svg');
  width: 24px;
  height: 24px;
}
.__flag_cr {
  content: url('src/static/flags/cr.svg');
  width: 24px;
  height: 24px;
}
.__flag_cu {
  content: url('src/static/flags/cu.svg');
  width: 24px;
  height: 24px;
}
.__flag_hr {
  content: url('src/static/flags/hr.svg');
  width: 24px;
  height: 24px;
}
.__flag_cw {
  content: url('src/static/flags/cw.svg');
  width: 24px;
  height: 24px;
}
.__flag_cy {
  content: url('src/static/flags/cy.svg');
  width: 24px;
  height: 24px;
}
.__flag_cz {
  content: url('src/static/flags/cz.svg');
  width: 24px;
  height: 24px;
}
.__flag_dk {
  content: url('src/static/flags/dk.svg');
  width: 24px;
  height: 24px;
}
.__flag_dj {
  content: url('src/static/flags/dj.svg');
  width: 24px;
  height: 24px;
}
.__flag_dm {
  content: url('src/static/flags/dm.svg');
  width: 24px;
  height: 24px;
}
.__flag_do {
  content: url('src/static/flags/do.svg');
  width: 24px;
  height: 24px;
}
.__flag_tl {
  content: url('src/static/flags/tl.svg');
  width: 24px;
  height: 24px;
}
.__flag_ec {
  content: url('src/static/flags/ec.svg');
  width: 24px;
  height: 24px;
}
.__flag_eg {
  content: url('src/static/flags/eg.svg');
  width: 24px;
  height: 24px;
}
.__flag_sv {
  content: url('src/static/flags/sv.svg');
  width: 24px;
  height: 24px;
}
.__flag_gq {
  content: url('src/static/flags/gq.svg');
  width: 24px;
  height: 24px;
}
.__flag_er {
  content: url('src/static/flags/er.svg');
  width: 24px;
  height: 24px;
}
.__flag_ee {
  content: url('src/static/flags/ee.svg');
  width: 24px;
  height: 24px;
}
.__flag_et {
  content: url('src/static/flags/et.svg');
  width: 24px;
  height: 24px;
}
.__flag_fk {
  content: url('src/static/flags/fk.svg');
  width: 24px;
  height: 24px;
}
.__flag_fo {
  content: url('src/static/flags/fo.svg');
  width: 24px;
  height: 24px;
}
.__flag_fj {
  content: url('src/static/flags/fj.svg');
  width: 24px;
  height: 24px;
}
.__flag_fi {
  content: url('src/static/flags/fi.svg');
  width: 24px;
  height: 24px;
}
.__flag_fr {
  content: url('src/static/flags/fr.svg');
  width: 24px;
  height: 24px;
}
.__flag_pf {
  content: url('src/static/flags/pf.svg');
  width: 24px;
  height: 24px;
}
.__flag_ga {
  content: url('src/static/flags/ga.svg');
  width: 24px;
  height: 24px;
}
.__flag_gm {
  content: url('src/static/flags/gm.svg');
  width: 24px;
  height: 24px;
}
.__flag_ge {
  content: url('src/static/flags/ge.svg');
  width: 24px;
  height: 24px;
}
.__flag_de {
  content: url('src/static/flags/de.svg');
  width: 24px;
  height: 24px;
}
.__flag_gh {
  content: url('src/static/flags/gh.svg');
  width: 24px;
  height: 24px;
}
.__flag_gi {
  content: url('src/static/flags/gi.svg');
  width: 24px;
  height: 24px;
}
.__flag_gr {
  content: url('src/static/flags/gr.svg');
  width: 24px;
  height: 24px;
}
.__flag_gl {
  content: url('src/static/flags/gl.svg');
  width: 24px;
  height: 24px;
}
.__flag_gd {
  content: url('src/static/flags/gd.svg');
  width: 24px;
  height: 24px;
}
.__flag_gu {
  content: url('src/static/flags/gu.svg');
  width: 24px;
  height: 24px;
}
.__flag_gt {
  content: url('src/static/flags/gt.svg');
  width: 24px;
  height: 24px;
}
.__flag_gg {
  content: url('src/static/flags/gg.svg');
  width: 24px;
  height: 24px;
}
.__flag_gn {
  content: url('src/static/flags/gn.svg');
  width: 24px;
  height: 24px;
}
.__flag_gw {
  content: url('src/static/flags/gw.svg');
  width: 24px;
  height: 24px;
}
.__flag_gy {
  content: url('src/static/flags/gy.svg');
  width: 24px;
  height: 24px;
}
.__flag_ht {
  content: url('src/static/flags/ht.svg');
  width: 24px;
  height: 24px;
}
.__flag_hn {
  content: url('src/static/flags/hn.svg');
  width: 24px;
  height: 24px;
}
.__flag_hk {
  content: url('src/static/flags/hk.svg');
  width: 24px;
  height: 24px;
}
.__flag_hu {
  content: url('src/static/flags/hu.svg');
  width: 24px;
  height: 24px;
}
.__flag_is {
  content: url('src/static/flags/is.svg');
  width: 24px;
  height: 24px;
}
.__flag_in {
  content: url('src/static/flags/in.svg');
  width: 24px;
  height: 24px;
}
.__flag_id {
  content: url('src/static/flags/id.svg');
  width: 24px;
  height: 24px;
}
.__flag_ir {
  content: url('src/static/flags/ir.svg');
  width: 24px;
  height: 24px;
}
.__flag_iq {
  content: url('src/static/flags/iq.svg');
  width: 24px;
  height: 24px;
}
.__flag_ie {
  content: url('src/static/flags/ie.svg');
  width: 24px;
  height: 24px;
}
.__flag_im {
  content: url('src/static/flags/im.svg');
  width: 24px;
  height: 24px;
}
.__flag_il {
  content: url('src/static/flags/il.svg');
  width: 24px;
  height: 24px;
}
.__flag_it {
  content: url('src/static/flags/it.svg');
  width: 24px;
  height: 24px;
}
.__flag_ci {
  content: url('src/static/flags/ci.svg');
  width: 24px;
  height: 24px;
}
.__flag_jm {
  content: url('src/static/flags/jm.svg');
  width: 24px;
  height: 24px;
}
.__flag_jp {
  content: url('src/static/flags/jp.svg');
  width: 24px;
  height: 24px;
}
.__flag_je {
  content: url('src/static/flags/je.svg');
  width: 24px;
  height: 24px;
}
.__flag_jo {
  content: url('src/static/flags/jo.svg');
  width: 24px;
  height: 24px;
}
.__flag_kz {
  content: url('src/static/flags/kz.svg');
  width: 24px;
  height: 24px;
}
.__flag_ke {
  content: url('src/static/flags/ke.svg');
  width: 24px;
  height: 24px;
}
.__flag_ko {
  content: url('src/static/flags/kr.svg');
  width: 24px;
  height: 24px;
}
.__flag_kw {
  content: url('src/static/flags/kw.svg');
  width: 24px;
  height: 24px;
}
.__flag_kg {
  content: url('src/static/flags/kg.svg');
  width: 24px;
  height: 24px;
}
.__flag_la {
  content: url('src/static/flags/la.svg');
  width: 24px;
  height: 24px;
}
.__flag_lv {
  content: url('src/static/flags/lv.svg');
  width: 24px;
  height: 24px;
}
.__flag_lb {
  content: url('src/static/flags/lb.svg');
  width: 24px;
  height: 24px;
}
.__flag_ls {
  content: url('src/static/flags/ls.svg');
  width: 24px;
  height: 24px;
}
.__flag_lr {
  content: url('src/static/flags/lr.svg');
  width: 24px;
  height: 24px;
}
.__flag_ly {
  content: url('src/static/flags/ly.svg');
  width: 24px;
  height: 24px;
}
.__flag_li {
  content: url('src/static/flags/li.svg');
  width: 24px;
  height: 24px;
}
.__flag_lt {
  content: url('src/static/flags/lt.svg');
  width: 24px;
  height: 24px;
}
.__flag_lu {
  content: url('src/static/flags/lu.svg');
  width: 24px;
  height: 24px;
}
.__flag_mo {
  content: url('src/static/flags/mo.svg');
  width: 24px;
  height: 24px;
}
.__flag_mk {
  content: url('src/static/flags/mk.svg');
  width: 24px;
  height: 24px;
}
.__flag_mg {
  content: url('src/static/flags/mg.svg');
  width: 24px;
  height: 24px;
}
.__flag_mw {
  content: url('src/static/flags/mw.svg');
  width: 24px;
  height: 24px;
}
.__flag_my {
  content: url('src/static/flags/my.svg');
  width: 24px;
  height: 24px;
}
.__flag_mv {
  content: url('src/static/flags/mv.svg');
  width: 24px;
  height: 24px;
}
.__flag_ml {
  content: url('src/static/flags/ml.svg');
  width: 24px;
  height: 24px;
}
.__flag_mt {
  content: url('src/static/flags/mt.svg');
  width: 24px;
  height: 24px;
}
.__flag_mh {
  content: url('src/static/flags/mh.svg');
  width: 24px;
  height: 24px;
}
.__flag_mr {
  content: url('src/static/flags/mr.svg');
  width: 24px;
  height: 24px;
}
.__flag_mu {
  content: url('src/static/flags/mu.svg');
  width: 24px;
  height: 24px;
}
.__flag_mx {
  content: url('src/static/flags/mx.svg');
  width: 24px;
  height: 24px;
}
.__flag_fm {
  content: url('src/static/flags/fm.svg');
  width: 24px;
  height: 24px;
}
.__flag_md {
  content: url('src/static/flags/md.svg');
  width: 24px;
  height: 24px;
}
.__flag_mc {
  content: url('src/static/flags/mc.svg');
  width: 24px;
  height: 24px;
}
.__flag_mn {
  content: url('src/static/flags/mn.svg');
  width: 24px;
  height: 24px;
}
.__flag_me {
  content: url('src/static/flags/me.svg');
  width: 24px;
  height: 24px;
}
.__flag_ms {
  content: url('src/static/flags/ms.svg');
  width: 24px;
  height: 24px;
}
.__flag_ma {
  content: url('src/static/flags/ma.svg');
  width: 24px;
  height: 24px;
}
.__flag_mz {
  content: url('src/static/flags/mz.svg');
  width: 24px;
  height: 24px;
}
.__flag_na {
  content: url('src/static/flags/na.svg');
  width: 24px;
  height: 24px;
}
.__flag_nr {
  content: url('src/static/flags/nr.svg');
  width: 24px;
  height: 24px;
}
.__flag_np {
  content: url('src/static/flags/np.svg');
  width: 24px;
  height: 24px;
}
.__flag_nl {
  content: url('src/static/flags/nl.svg');
  width: 24px;
  height: 24px;
}
.__flag_nc {
  content: url('src/static/flags/nc.svg');
  width: 24px;
  height: 24px;
}
.__flag_nz {
  content: url('src/static/flags/nz.svg');
  width: 24px;
  height: 24px;
}
.__flag_ni {
  content: url('src/static/flags/ni.svg');
  width: 24px;
  height: 24px;
}
.__flag_ne {
  content: url('src/static/flags/ne.svg');
  width: 24px;
  height: 24px;
}
.__flag_ng {
  content: url('src/static/flags/ng.svg');
  width: 24px;
  height: 24px;
}
.__flag_nu {
  content: url('src/static/flags/nu.svg');
  width: 24px;
  height: 24px;
}
.__flag_mp {
  content: url('src/static/flags/mp.svg');
  width: 24px;
  height: 24px;
}
.__flag_kp {
  content: url('src/static/flags/kp.svg');
  width: 24px;
  height: 24px;
}
.__flag_no {
  content: url('src/static/flags/no.svg');
  width: 24px;
  height: 24px;
}
.__flag_om {
  content: url('src/static/flags/om.svg');
  width: 24px;
  height: 24px;
}
.__flag_pk {
  content: url('src/static/flags/pk.svg');
  width: 24px;
  height: 24px;
}
.__flag_pw {
  content: url('src/static/flags/pw.svg');
  width: 24px;
  height: 24px;
}
.__flag_ps {
  content: url('src/static/flags/ps.svg');
  width: 24px;
  height: 24px;
}
.__flag_pa {
  content: url('src/static/flags/pa.svg');
  width: 24px;
  height: 24px;
}
.__flag_pg {
  content: url('src/static/flags/pg.svg');
  width: 24px;
  height: 24px;
}
.__flag_py {
  content: url('src/static/flags/py.svg');
  width: 24px;
  height: 24px;
}
.__flag_pe {
  content: url('src/static/flags/pe.svg');
  width: 24px;
  height: 24px;
}
.__flag_ph {
  content: url('src/static/flags/ph.svg');
  width: 24px;
  height: 24px;
}
.__flag_pn {
  content: url('src/static/flags/pn.svg');
  width: 24px;
  height: 24px;
}
.__flag_pl {
  content: url('src/static/flags/pl.svg');
  width: 24px;
  height: 24px;
}
.__flag_pt {
  content: url('src/static/flags/pt.svg');
  width: 24px;
  height: 24px;
}
.__flag_pr {
  content: url('src/static/flags/pr.svg');
  width: 24px;
  height: 24px;
}
.__flag_qa {
  content: url('src/static/flags/qa.svg');
  width: 24px;
  height: 24px;
}
.__flag_re {
  content: url('src/static/flags/re.svg');
  width: 24px;
  height: 24px;
}
.__flag_ro {
  content: url('src/static/flags/ro.svg');
  width: 24px;
  height: 24px;
}
.__flag_ru {
  content: url('src/static/flags/ru.svg');
  width: 24px;
  height: 24px;
}
.__flag_rw {
  content: url('src/static/flags/rw.svg');
  width: 24px;
  height: 24px;
}
.__flag_bl {
  content: url('src/static/flags/bl.svg');
  width: 24px;
  height: 24px;
}
.__flag_ws {
  content: url('src/static/flags/ws.svg');
  width: 24px;
  height: 24px;
}
.__flag_sm {
  content: url('src/static/flags/sm.svg');
  width: 24px;
  height: 24px;
}
.__flag_st {
  content: url('src/static/flags/st.svg');
  width: 24px;
  height: 24px;
}
.__flag_sa {
  content: url('src/static/flags/sa.svg');
  width: 24px;
  height: 24px;
}
.__flag_sn {
  content: url('src/static/flags/sn.svg');
  width: 24px;
  height: 24px;
}
.__flag_rs {
  content: url('src/static/flags/rs.svg');
  width: 24px;
  height: 24px;
}
.__flag_sc {
  content: url('src/static/flags/sc.svg');
  width: 24px;
  height: 24px;
}
.__flag_sl {
  content: url('src/static/flags/sl.svg');
  width: 24px;
  height: 24px;
}
.__flag_sg {
  content: url('src/static/flags/sg.svg');
  width: 24px;
  height: 24px;
}
.__flag_sx {
  content: url('src/static/flags/sx.svg');
  width: 24px;
  height: 24px;
}
.__flag_sk {
  content: url('src/static/flags/sk.svg');
  width: 24px;
  height: 24px;
}
.__flag_sl {
  content: url('src/static/flags/sl.svg');
  width: 24px;
  height: 24px;
}
.__flag_sb {
  content: url('src/static/flags/sb.svg');
  width: 24px;
  height: 24px;
}
.__flag_so {
  content: url('src/static/flags/so.svg');
  width: 24px;
  height: 24px;
}
.__flag_za {
  content: url('src/static/flags/za.svg');
  width: 24px;
  height: 24px;
}
.__flag_kr {
  content: url('src/static/flags/kr.svg');
  width: 24px;
  height: 24px;
}
.__flag_ss {
  content: url('src/static/flags/ss.svg');
  width: 24px;
  height: 24px;
}
.__flag_es {
  content: url('src/static/flags/es.svg');
  width: 24px;
  height: 24px;
}
.__flag_lk {
  content: url('src/static/flags/lk.svg');
  width: 24px;
  height: 24px;
}
.__flag_sh {
  content: url('src/static/flags/sh.svg');
  width: 24px;
  height: 24px;
}
.__flag_kn {
  content: url('src/static/flags/kn.svg');
  width: 24px;
  height: 24px;
}
.__flag_lc {
  content: url('src/static/flags/lc.svg');
  width: 24px;
  height: 24px;
}
.__flag_mf {
  content: url('src/static/flags/mf.svg');
  width: 24px;
  height: 24px;
}
.__flag_pm {
  content: url('src/static/flags/pm.svg');
  width: 24px;
  height: 24px;
}
.__flag_vc {
  content: url('src/static/flags/vc.svg');
  width: 24px;
  height: 24px;
}
.__flag_sd {
  content: url('src/static/flags/sd.svg');
  width: 24px;
  height: 24px;
}
.__flag_sr {
  content: url('src/static/flags/sr.svg');
  width: 24px;
  height: 24px;
}
.__flag_sj {
  content: url('src/static/flags/sj.svg');
  width: 24px;
  height: 24px;
}
.__flag_sz {
  content: url('src/static/flags/sz.svg');
  width: 24px;
  height: 24px;
}
.__flag_se {
  content: url('src/static/flags/se.svg');
  width: 24px;
  height: 24px;
}
.__flag_ch {
  content: url('src/static/flags/ch.svg');
  width: 24px;
  height: 24px;
}
.__flag_sy {
  content: url('src/static/flags/sy.svg');
  width: 24px;
  height: 24px;
}
.__flag_tw {
  content: url('src/static/flags/tw.svg');
  width: 24px;
  height: 24px;
}
.__flag_tj {
  content: url('src/static/flags/tj.svg');
  width: 24px;
  height: 24px;
}
.__flag_tz {
  content: url('src/static/flags/tz.svg');
  width: 24px;
  height: 24px;
}
.__flag_th {
  content: url('src/static/flags/th.svg');
  width: 24px;
  height: 24px;
}
.__flag_tg {
  content: url('src/static/flags/tg.svg');
  width: 24px;
  height: 24px;
}
.__flag_tk {
  content: url('src/static/flags/tk.svg');
  width: 24px;
  height: 24px;
}
.__flag_to {
  content: url('src/static/flags/to.svg');
  width: 24px;
  height: 24px;
}
.__flag_tt {
  content: url('src/static/flags/tt.svg');
  width: 24px;
  height: 24px;
}
.__flag_tn {
  content: url('src/static/flags/tn.svg');
  width: 24px;
  height: 24px;
}
.__flag_tr {
  content: url('src/static/flags/tr.svg');
  width: 24px;
  height: 24px;
}
.__flag_tm {
  content: url('src/static/flags/tm.svg');
  width: 24px;
  height: 24px;
}
.__flag_tc {
  content: url('src/static/flags/tc.svg');
  width: 24px;
  height: 24px;
}
.__flag_tv {
  content: url('src/static/flags/tv.svg');
  width: 24px;
  height: 24px;
}
.__flag_ae {
  content: url('src/static/flags/ae.svg');
  width: 24px;
  height: 24px;
}
.__flag_ug {
  content: url('src/static/flags/ug.svg');
  width: 24px;
  height: 24px;
}
.__flag_en {
  content: url('src/static/flags/gb.svg');
  width: 24px;
  height: 24px;
}
.__flag_uk {
  content: url('src/static/flags/ua.svg');
  width: 24px;
  height: 24px;
}
.__flag_uy {
  content: url('src/static/flags/uy.svg');
  width: 24px;
  height: 24px;
}
.__flag_uz {
  content: url('src/static/flags/uz.svg');
  width: 24px;
  height: 24px;
}
.__flag_vu {
  content: url('src/static/flags/vu.svg');
  width: 24px;
  height: 24px;
}
.__flag_va {
  content: url('src/static/flags/va.svg');
  width: 24px;
  height: 24px;
}
.__flag_ve {
  content: url('src/static/flags/ve.svg');
  width: 24px;
  height: 24px;
}
.__flag_us {
  content: url('src/static/flags/us.svg');
  width: 24px;
  height: 24px;
}
.__flag_vn {
  content: url('src/static/flags/vn.svg');
  width: 24px;
  height: 24px;
}
.__flag_vi {
  content: url('src/static/flags/vi.svg');
  width: 24px;
  height: 24px;
}
.__flag_wf {
  content: url('src/static/flags/wf.svg');
  width: 24px;
  height: 24px;
}
.__flag_eh {
  content: url('src/static/flags/eh.svg');
  width: 24px;
  height: 24px;
}
.__flag_ye {
  content: url('src/static/flags/ye.svg');
  width: 24px;
  height: 24px;
}
.__flag_yt {
  content: url('src/static/flags/yt.svg');
  width: 24px;
  height: 24px;
}
.__flag_ki {
  content: url('src/static/flags/ki.svg');
  width: 24px;
  height: 24px;
}
.__flag_zm {
  content: url('src/static/flags/zm.svg');
  width: 24px;
  height: 24px;
}
.__flag_zw {
  content: url('src/static/flags/zw.svg');
  width: 24px;
  height: 24px;
}
